<template>
  <div>
    <v-card
        v-for="(item,index) in items"
        :key="index"
        elevation="3"
        class="mx-auto my-3"
    >


      <v-container>
        <v-row dense class="align-center ">
          <v-col cols="1">
            <v-icon>
              {{item.icon}}
            </v-icon>
          </v-col>
          <v-col cols="11">
            <v-card-text>{{item.text}}</v-card-text>
          </v-col>
        </v-row>
      </v-container>


    </v-card>

  </div>
</template>

<script>
export default {
  name: "Remember",
  data(){
    return{
      items:[
        {
          text:'El pago de esta reserva se guardará en un monedero virtual que se le asigna automáticamente a usted y con el que podrá consumir en nuestro Quiosco efectuando los pagos con este.',
          icon: 'mdi-wallet-outline'
        },
        {
          text:'No se permite cancelación de las reservas de una mesa.',
          icon: 'mdi-clock-time-three-outline'
        },
        {
          text:'El precio de una mesa dependerá del número de personas que caben en ella.',
          icon: 'mdi-currency-usd'
        },
      ]
    }
  },
}
</script>

<style scoped>

</style>