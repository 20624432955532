<template>
  <div>
    <!--    <stepper-custom :src="event_data.image" :step="1"></stepper-custom>-->
    <stepper-custom :step="3"></stepper-custom>

    <v-container>
      <v-row>
        <v-col cols="12"
               xs="12"
               sm="6"
               lg="6"

        >
          <div class="text-uppercase font-weight-black primary--text text-h5 mb-1"> Detalle de la Reserva</div>
          <v-card
              color="#F3F3F3"
              class="pa-4"
              tile
              elevation="0"
          >
            <div v-for="(item,index) in resume[1]" :key="index">
              <p class="font-weight-light text-uppercase">
                {{ item.label }}
              </p>
              <p class="font-weight-black">
                {{ item.value }}
              </p>
            </div>
            <v-container>
              <v-card
                  color="#EDF4F7"
                  tile
                  elevation="0"
                  class="pa-3"
              >
                <div class="text-right font-weight-light text-uppercase my-1">A pagar:</div>
                <div class="text-right font-weight-bold text-uppercase text-h5">{{resume[0]}} €</div>
              </v-card>
            </v-container>
          </v-card>
          <div class="text-h5 font-weight-bold mb-2">Recuerda que...</div>
          <remember></remember>
        </v-col>
        <v-col cols="12"
               xs="12"
               sm="6"
               lg="6"
        >
          <div class="text-uppercase font-weight-black white--text text-h5 mb-1">p</div>
          <v-card
              color="#F3F3F3"
              class="pa-4 mb-3"
              tile
              elevation="0"
          >
            <p class="primary--text text-uppercase">Nombre y Apellido</p>
            <v-text-field  v-model="name" name="name" placeholder="" required solo flat @change="isCompleted"></v-text-field>
            <p class="primary--text text-uppercase">teléfono</p>
            <v-text-field v-model="phone" name="phone" placeholder="" required solo flat @change="isCompleted"></v-text-field>
            <p class="primary--text text-uppercase">email</p>
            <v-text-field v-model="email"  name="email" placeholder="" required solo flat @change="isCompleted"></v-text-field>
          </v-card>
          <v-card color="#F3F3F3"
                  class="pa-4 mb-3"
                  tile
                  elevation="0">
            <card-form
                btn-confirm="Confirmar reserva"
                :url-form="`${$store.state.api_backend}api/${$store.state.reservation.id}/pay/${email}`"
                :cant-send="disabled"
            ></card-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CardForm from "@/components/CardForm";
import StepperCustom from "@/components/StepperCustom";
import Remember from "@/components/Remember";
export default {
  name: "Payment",
  components: {Remember, StepperCustom, CardForm},
  data(){
    return{
      phone: '',
      email: '',
      name: '',
      disabled: true,
    }
  },
  mounted() {
    this.$store.commit('setLoading', false)
  },
  methods:{
    getDate(scheduleDate){
      let date = scheduleDate.split('T')
      const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
      let eventDate = new Date(date[0])
      return {
        date: eventDate.toLocaleString('es-es', options),
        hour: date[1].substr(0, 5)
      }
    },
    isCompleted(){
      this.disabled = !(this.phone && this.email && this.name)
    }
  },
  computed: {
    resume() {
      const reservation = this.$store.state.reservation
      const amphiEvents = this.$store.state.amphiEvents
      const amphiEvent = amphiEvents.find(elem => elem.id === reservation.event_schedule_id.amphi_event_id)
      const amphiDate = this.getDate(reservation.event_schedule_id.schedule)
      const price = reservation.amphi_table.capacity * amphiEvent.price
      return [price,[
        {
          label: 'Evento',
          value: amphiEvent.name
        }, {
          label: 'Fecha',
          value: amphiDate.date
        }, {
          label: 'Horario',
          value: amphiDate.hour + ' HS'
        }, {
          label: 'Mesa',
          value: reservation.amphi_table.name
        }, {
          label: 'Asientos',
          value: reservation.amphi_table.capacity
        }
      ]]
    },
    event_data(){
      const reservation = this.$store.state.reservation
      const amphiEvents = this.$store.state.amphiEvents
      return  amphiEvents.find(elem => elem.id === reservation.event_schedule_id.amphi_event_id)

    }
  },
}
</script>

<style scoped>

</style>